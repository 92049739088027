import * as React from "react"
import { Container, Row, Col } from 'reactstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const coords = { lat: 47.3029163, lng: 39.7227977 };

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={17}
    defaultCenter={coords}
    options={{disableDefaultUI: true}}
  >
    <Marker position={coords} />
  </GoogleMap>
))

const fullHeightStyle = {
    height: "100%",
     alignSelf: "stretch"

}

const indexHeightStyle = {
     height: `400px` 
}

const mapBlockFullPageStyle = {height: "100%"};

const MapBlock = ({isFullHeight}) =>{
  const containerElementStyle = isFullHeight ? fullHeightStyle : indexHeightStyle;

  return (
      <div className="map-block" style={isFullHeight ? mapBlockFullPageStyle : {}}>
        <Container className="map-block-container">
            <Row>
                <Col xs="3" className="map-block-text-col">
                    <div className="map-block-text">
                        <div className="map-block-title">
                            Контакты
                        </div>
                        <p>
                            Адрес: <br />
                            п. Верхнетемерницкий, <br />
                            ул. Венеры, д. 21<br />
                            <br />
                            Телефон: <br />
                            +7 (909) 417-77-77  <br />
                            <br />
                            E-mail: <br />
                            order@ohm-electronics.ru
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
        <MyMapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDKJJ_NTN26VOR4EGaX5OhQzVziRjREzkQ&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={containerElementStyle} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>

  )
}

export default MapBlock