import * as React from "react"
import { Container, Row, Col } from 'reactstrap';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";


// data
const links = [
    {
      text: "Главная",
      url: "/"
    },
    {
      text: "О компании",
      url: "/about"
    },
    {
      text: "Предложения",
      url: "/offers"
    },
    {
      text: "Контакты",
      url: "/contacts"
    }
  ]

const Header = () => {
  return (
      <header className="header">
          <div className="blueBar">
              <Container>
                  <div className="blueBarInner">+7 (909) 417-77-77 | order@ohm-electronics.ru</div>
              </Container>
          </div> 
          <Container>
          <Row>
          <Col>
              <div className="headerLogo">
              <Link to="/">
              <StaticImage src="../images/logo.png" alt="Ohm Electronics" style={{marginRight: "11px"}}/>
                  <span>ohm</span>-electronics
                  </Link>
                  </div>
                  </Col>
              <Col>
              <menu className="headerMenu">
                  <ul>
                  {links.map(link => (
                    <li key={link.url} >
                      <span>
                        <Link to={link.url} activeClassName="active">{link.text}</Link>
                      </span>
                    </li>
                  ))}
                  </ul>
              </menu></Col>
              </Row>
          </Container>
      </header>
  )
}

export default Header
