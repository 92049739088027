import * as React from "react"
import { Container, Row, Col } from 'reactstrap';

const secondCol = {
    color: "#666666",
    textAlign: "right"
}

const Footer = () => {
  return (
      <footer className="footer">
        <Container>
            <Row>
                <Col>Copyright © 2021 Ohm Electronics - All Rights Reserved</Col>
                <Col style={secondCol}>Ohm Electronics</Col>
            </Row>
        </Container>
      </footer>
  )
}

export default Footer
