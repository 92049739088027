import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/style.scss"
import Header from "../components/Header.js"
import Footer from "../components/Footer.js"

export default function Layout({ children }) {
  return (
    <main id="layout">
        <Header/>
         {children}
        <Footer/>
    </main>
  )
}